<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      hide-footer
      ok-variant="dark"
      title="Kayıt Bilgileri"
      modal-class="modal-dark"
      v-model="isPreviewModal"
      size="lg">

    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="BookIcon"/>
          <span>Müşteri Bilgileri</span>
        </template>

        <table class="mt-2 mt-xl-0 w-100">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">Müşteri Tipi</span></th>
            <td class="pb-50 float-right">{{ previewData.customerType }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kod</span></th>
            <td class="py-50 float-right">{{ previewData.code }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Ünvan</span></th>
            <td class="py-50 float-right">{{ previewData.name }}</td>
          </tr>
          <tr class="border-bottom" v-for="(data, index) in previewData.values" :key="data.caption" v-if="data.caption != 'Kod'">
            <th class="py-50"><span class="font-weight-bold">{{ data.caption }}</span></th>
            <td class="py-50 float-right" v-if="data.valueType === 2"><active-column-table :data="data.value"></active-column-table></td>
            <td class="py-50 float-right" v-if="data.valueType !== 2">{{ data.value }}</td>
          </tr>
        </table>

      </b-tab>

      <b-tab v-if="addressVisible">
        <template #title>
          <feather-icon icon="MapIcon"/>
          <span>Adres Bilgileri</span>
        </template>
        <b-card no-body class="border mt-1">
          <b-table :items="addressDataList" :fields="addressFields" class="mb-0" striped responsive bordered hover>

            <template #cell(isActive)="data">
              <active-column-table :data="data.value"></active-column-table>
            </template>
            <template #cell(createdOn)="data">
              <date-column-table :data="data.value"></date-column-table>
            </template>

          </b-table>
        </b-card>
      </b-tab>

      <b-tab v-if="contactsVisible">
        <template #title>
          <feather-icon icon="UsersIcon"/>
          <span>İlgili Kişiler</span>
        </template>
        <b-card no-body class="border mt-1">
          <b-table :items="contactsDataList" :fields="contactFields" class="mb-0" striped responsive bordered hover>

            <template #cell(isActive)="data">
              <active-column-table :data="data.value"></active-column-table>
            </template>
            <template #cell(createdOn)="data">
              <date-column-table :data="data.value"></date-column-table>
            </template>

          </b-table>
        </b-card>
      </b-tab>
    </b-tabs>

    <div class="border-bottom my-1"></div>

    <b-row class="d-flex justify-content-end align-items-center">
      <b-button
          class="mr-1"
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$emit('update:is-preview-modal', false)">
        <feather-icon icon="XCircleIcon" size="16"/>
        <span class="align-middle" role="button"> Kapat</span>
      </b-button>
    </b-row>

    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {BButton, BCard, BCol, BFormCheckbox, BFormGroup, BFormInput, BModal, BRow, BTab, BTable, BTabs} from "bootstrap-vue"
import {ref, watch} from '@vue/composition-api'
import {useToast} from "vue-toastification/composition"
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {ActiveColumnTable, BooleanColumnTable, DateTimeColumnTable, DateColumnTable} from "@/components/Table"

export default {
  components: {
    DateColumnTable,
    DateTimeColumnTable,
    BooleanColumnTable,
    ActiveColumnTable,
    Overlay,

    BFormInput,
    BFormGroup,
    BCol,
    BFormCheckbox,
    BTable,
    BCard,
    BTab,
    BTabs,
    BRow,
    BButton,
    BModal
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref({})
    const addressDataList = ref([])
    const addressVisible = ref(true)
    const contactsDataList = ref([])
    const contactsVisible = ref(true)

    const addressFields = [
      {key: 'addressType', label: 'Adres Tipi', thStyle: {width: "8%"}},
      {key: 'name', label: 'İsim', thStyle: {width: "12%"}},
      {key: 'countryName', label: 'Ülke', thStyle: {width: "8%"}},
      {key: 'cityName', label: 'Şehir', thStyle: {width: "8%"}},
      {key: 'address', label: 'Adres'},
      {key: 'isActive', label: 'Durum', thStyle: {width: "1%"}},
      {key: 'createdOn', label: 'Tarih', thStyle: {width: "1%"}},
    ]

    const contactFields = [
      {key: 'name', label: 'İsim'},
      {key: 'lastName', label: 'Soyisim'},
      {key: 'gender', label: 'Cinsiyet'},
      {key: 'title', label: 'Ünvan'},
      {key: 'email', label: 'Email'},
      {key: 'phone', label: 'Telefon'},
      {key: 'isActive', label: 'Durum', thStyle: {width: "1%"}},
      {key: 'createdOn', label: 'Tarih', thStyle: {width: "1%"}},
    ]

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined && props.isPreviewModal) {

        busy.value = true
        store.dispatch('store/previewItem', {id: dataValue.id}).then(response => {
          previewData.value = response.data.data
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })

        busy.value = true
        store.dispatch('store/fetchAddressList', {customerId: dataValue.id}).then(response => {
          addressDataList.value = response.data.data
        }).catch(error => {
          if (error.response.status === 403) {
            addressVisible.value = false
          } else {
            toastMessage(toast, 'danger', error.response.data.message)
          }
        }).finally(() => {
          busy.value = false
        })

        busy.value = true
        store.dispatch('store/fetchContactList', {customerId: dataValue.id}).then(response => {
          contactsDataList.value = response.data.data
        }).catch(error => {
          if (error.response.status === 403) {
            contactsVisible.value = false
          } else {
            toastMessage(toast, 'danger', error.response.data.message)
          }
        }).finally(() => {
          busy.value = false
        })

      }
    })

    return {
      busy,
      previewData,
      addressDataList,
      addressVisible,
      addressFields,
      contactsDataList,
      contactsVisible,
      contactFields,

      recordStatusFormat,
      recordStatusVariant
    }
  },
}
</script>
