<template>

    <b-card no-body>
        <div class="m-1">
            <b-row>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <v-select
                            v-model="perPage"
                            class="per-page-selector d-inline-block mx-50"
                            :options="perPageOptions"
                            :clearable="false"/>
                </b-col>
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
                        <header-table :new-route="{ name: 'definition-customer-add' }" :re-fetch-data="reFetchData"></header-table>
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-table
                ref="refListTable"
                primary-key="id"
                empty-text="Kayıt bulunamadı"
                class="position-relative table-sticky"
                :items="fetchList"
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                responsive striped bordered hover show-empty>

            <template #cell(id)="data">
                <id-column-table :data="data.value" :route-name="'definition-customer-edit'"></id-column-table>
            </template>

            <template #cell(name)="data">
                <div class="text-nowrap">
                    {{ data.value }}
                </div>
            </template>

            <template #cell(mainSectorName)="data">
                <div class="text-nowrap">
                    {{ data.value }}
                </div>
            </template>

            <template #cell(companyLogo)="data">
                <b-avatar
                        ref="previewEl"
                        :src="getApiFile(data.value)"
                        :variant="`light-primary`"
                        size="30px">
                    <feather-icon icon="BookIcon" v-if="!data.value"/>
                </b-avatar>
            </template>

            <template #cell(isActive)="data">
                <active-column-table :data="data.value"></active-column-table>
            </template>

            <template #cell(createdOn)="data">
                <date-column-table :data="data.value"></date-column-table>
            </template>

            <template #cell(salesmanName)="data">
                <div class="text-nowrap">
                    {{ data.value }}
                </div>
            </template>

            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft>
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                        </template>
                        <b-dropdown-item :to="{ name: 'definition-customer-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon"/>
                            <span class="align-middle ml-50">Değiştir</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="demandRequest(data.item)">
                            <feather-icon icon="MailIcon"/>
                            <span class="align-middle ml-50">Talep İsteği Gönder</span>
                        </b-dropdown-item>
                        <remove-button-table :data="data" :re-fetch-data="reFetchData" :dropdown="true"></remove-button-table>
                    </b-dropdown>
                    <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
                    <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
                </div>
            </template>

        </b-table>

        <div class="mx-2 mb-2">
            <b-row>
                <footer-table :data-meta="dataMeta"></footer-table>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18"/>
                        </template>

                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18"/>
                        </template>

                    </b-pagination>
                </b-col>
            </b-row>
        </div>

        <Overlay :busy="busy"></Overlay>
        <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>
    </b-card>

</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BAvatar, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import {
    FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable, ActiveColumnTable, IdColumnTable, HeaderTable
} from '@/components/Table';

import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import Overlay from "@/components/Overlay.vue";
import Preview from "@/views/definition/customer/Preview.vue";
import {getApiFile} from "@core/utils/filter"
import Vue from "vue";

export default {
    components: {
        BDropdownItem,
        BDropdown,
        BAvatar,
        BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip,
        Overlay, Preview, HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable, ActiveColumnTable, IdColumnTable,
        vSelect,
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
        })

        const {
            tableColumns,
            perPage,
            currentPage,
            totalCount,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            busy,

            fetchList,
            reFetchData,
        } = useList()

        const dataPreviewRecord = ref({})
        const isPreviewModal = ref(false)
        const previewRecord = (dataItem) => {
            dataPreviewRecord.value = dataItem
            isPreviewModal.value = true
        }

        const demandRequest = (dataItem) => {
            Vue.swal({
                title: 'Onaylıyor musunuz?',
                text: 'Talep isteği gönderilecektir!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Gönder',
                cancelButtonText: 'Vazgeç',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store.dispatch('store/demandItem', {id: dataItem.id}).then(response => {
                        if (response.data.success) {
                            reFetchData()
                            Vue.swal({
                                icon: 'success',
                                title: 'Başarılı!',
                                text: 'Finans departmanına talep isteği gönderildi.',
                                confirmButtonText: 'Tamam',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                            })
                        }
                    }).catch(error => {
                        Vue.swal({
                            icon: 'error',
                            title: 'Hata!',
                            text: error.response.data.message,
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                        })
                    })
                }
            })
        }

        return {
            tableColumns,
            perPage,
            currentPage,
            totalCount,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            busy,
            isPreviewModal,
            dataPreviewRecord,

            fetchList,
            reFetchData,
            previewRecord,
            getApiFile,
            demandRequest
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
